import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { BaseContext } from '../../../redux/models'
import { cancelLightningCashOut, resetState, setRefundedPoints } from '../../../redux/slices/cashOut'
import { AppDispatch } from '../../../redux/store'
import { loadConfigs } from '../../../redux/slices/user'

interface CancelLightningDialogProps {
  baseContext: BaseContext
  cashOutId: string
  openCancelDialog: boolean
  setOpenCancelDialog: React.Dispatch<React.SetStateAction<boolean>>
}

interface CancelLightningSuccessDialogProps {
  baseContext: BaseContext
  refundedPoints: string
}

export const CancelLightningSuccessDialog = ({ baseContext, refundedPoints }: CancelLightningSuccessDialogProps) => {
  const dispatch = useDispatch<AppDispatch>()

  return (
    <Dialog
      open={refundedPoints !== ''}
      onClose={() => {
        dispatch(setRefundedPoints(''))
        dispatch(resetState())
        dispatch(loadConfigs(baseContext))
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Cancellation Confirmed'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your cash out request has been successfully cancelled, and your{' '}
          <span style={{ color: '#00A2FF' }}>{refundedPoints}</span> points have been refunded.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(setRefundedPoints(''))
            dispatch(resetState())
            dispatch(loadConfigs(baseContext))
          }}
          color="primary"
          autoFocus
        >
          Okay!
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const CancelLightningDialog = (props: CancelLightningDialogProps) => {
  const { baseContext, cashOutId, openCancelDialog, setOpenCancelDialog } = props
  const dispatch = useDispatch<AppDispatch>()

  const cancelCashOut = useCallback(() => {
    setOpenCancelDialog(false)
    dispatch(cancelLightningCashOut({ baseContext, cashOutId }))
  }, [baseContext, cashOutId, dispatch, setOpenCancelDialog])

  return (
    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" mt={5}>
      <Button onClick={() => setOpenCancelDialog(true)} disabled={openCancelDialog}>
        Cancel
      </Button>

      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your points will be immediately refunded, and you can initiate a new cash out at any time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)}>No</Button>
          <Button onClick={cancelCashOut} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
