import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { firebaseAuth } from '../../firebase/firebase'

export interface AuthState {
  authenticated: boolean
  loadingAuth: boolean
  needVerification: boolean
  serverError: boolean // internal cloud function errors
  error: string // external auth provider error
  emailError: string
  success: string
}

const initialState: AuthState = {
  authenticated: false,
  loadingAuth: true,
  needVerification: false,
  serverError: false,
  error: '',
  emailError: '',
  success: '',
}

// Handles Firebase auth errors for email login (copied from Firebase UI logic)
const handleEmailError = (code: string) => {
  switch (code) {
    case 'auth/email-already-in-use':
      return 'The email address is already used by another account'
    case 'auth/too-many-requests':
      return 'You have entered an incorrect password too many times. Please try again in a few minutes.'
    case 'auth/user-cancelled':
      return 'Please authorize the required permissions to sign in to the application'
    case 'auth/user-not-found':
      return `That email address doesn't match an existing account`
    case 'auth/weak-password':
      return 'Strong passwords have at least 6 characters and a mix of letters and numbers'
    case 'auth/wrong-password':
      return `The email and password you entered don't match`
    case 'auth/network-request-failed':
      return 'A network error has occurred'
    case 'auth/code-expired':
      return 'This code is no longer valid'
    case 'auth/invalid-action-code':
      return 'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.'
    default:
      return 'An unexpected error has occurred. Please try again!'
  }
}

export const externalProviderLogin = createAsyncThunk(
  'externalProviderLogin',
  async ({ providerName }: { providerName: string }, { dispatch }) => {
    // TODO: Handle dynamically
    if (providerName === 'google') {
      const provider = new GoogleAuthProvider()
      signInWithPopup(firebaseAuth, provider)
        .then((userCredential) => {
          //   console.log(userCredential)
        })
        .catch((error) => {
          dispatch(setError(error.message))
        })
    } else if (providerName === 'facebook') {
      const provider = new FacebookAuthProvider()
      signInWithPopup(firebaseAuth, provider)
        .then((userCredential) => {
          //   console.log(userCredential)
        })
        .catch((error) => {
          dispatch(setError(error.message))
        })
    } else if (providerName === 'apple') {
      const provider = new OAuthProvider('apple.com')
      signInWithPopup(firebaseAuth, provider)
        .then((userCredential) => {
          //   console.log(userCredential)
        })
        .catch((error) => {
          dispatch(setError(error.message))
        })
    }
  },
)

export const emailPasswordLogin = createAsyncThunk(
  'emailPasswordLogin',
  async ({ email, password }: { email: string; password: string }, { dispatch }) => {
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCredential) => {
        // console.log(userCredential)
      })
      .catch((error) => {
        const errorMessage = handleEmailError(error.code)
        dispatch(setEmailError(errorMessage))
      })
  },
)

export const logout = createAsyncThunk('signOut', async (_data, { dispatch }) => {
  try {
    // Reset all redux states then load user back to login page
    dispatch(setSignOut())
    dispatch(setLoadingAuth(true))
    signOut(firebaseAuth)
  } catch (err) {
    dispatch(setLoadingAuth(false))
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSignOut: (state) => {
      state.authenticated = false
      state.loadingAuth = false
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload
    },
    setLoadingAuth: (state, action: PayloadAction<boolean>) => {
      state.loadingAuth = action.payload
    },
    setNeedVerification: (state, action: PayloadAction<boolean>) => {
      state.needVerification = action.payload
    },
    setServerError: (state, action: PayloadAction<boolean>) => {
      state.serverError = action.payload
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    setEmailError: (state, action: PayloadAction<string>) => {
      state.emailError = action.payload
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.success = action.payload
    },
  },
})

export const {
  setSignOut,
  setAuthenticated,
  setLoadingAuth,
  setNeedVerification,
  setServerError,
  setError,
  setEmailError,
  setSuccess,
} = authSlice.actions
