import QRCode from 'qrcode'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { httpsCallable } from 'firebase/functions'
import { CASHOUT_ACTION_TYPE } from '../../constants'
import { firebaseAuth, firebaseFunctions } from '../../firebase/firebase'
import { BaseContext, CashOutContext, VerificationContext } from '../models'
import { setServerError } from './auth'
import { loadConfigs, setBlingPoints, setBlingPointsBTCValue, SupportedCurrency } from './user'
import { isMobileSafari } from '../../utils/user'

export interface SupportedCashoutPlatform {
  name: string
  currencies: string[] // TODO: Generic should take in whatever currencies names are
}

interface CashoutConfig {
  unavailableCashoutCode: string
  minRequiredPoints: string
  isCashoutAvailable: string
  nextCashoutAvailableAt: string
  amountBtc: string
  currencies: Record<string, SupportedCurrency>
  platforms: Record<string, SupportedCashoutPlatform> // TODO: Generic should take in whatever currencies names are
  lastCashout: LastCashout
  lastCashoutEmails: Record<string, string>
  pendingReviewMessage: string
  lightningWalletWhitelist: Record<string, LightningWallet>
}

export interface LightningWallet {
  name: string
  urlScheme: string
  bundleId: string
  iconUrl: string
  iosStoreListingUrl: string
  androidStoreListingUrl: string
  recommended: boolean
}

type LightningWallets = Record<string, LightningWallet>

interface LastCashout {
  cashoutId: string
  cashoutEmail: string
  fundsSentAt: string
  status: string
  cryptoAmount: string
  cryptoSymbol: string
  cryptoName: string
  transferVia: string
  lnurl: string
}

interface CashOutState {
  cashOutConfig: CashoutConfig
  selectedCurrency: SupportedCurrency
  selectedCashoutPlatform: string
  selectedCoinbase: Record<string, unknown> // First time user "existing Coinbase account" or "new Coinbase account" selection page (not to be confused with cash out platform selection)
  loadingCashOutConfig: boolean
  loadingCashOut: boolean
  loadingVerification: boolean
  verificationRequired: boolean
  cashOutButtonDisabled: boolean
  verifyButtonDisabled: boolean
  cashOutErrorMessage: string
  verifyErrorMessage: string
  verifyEmail: string
  lastestCashOutEmailInput: string
  lnurl: string
  lnurlQrCode: string
  refundedPoints: string
  loadingCancelLightning: boolean
  selectedLightningWallet: LightningWallet | null
}

const initialState: CashOutState = {
  cashOutConfig: {} as CashoutConfig & LastCashout,
  selectedCurrency: {} as SupportedCurrency,
  selectedCashoutPlatform: '',
  selectedCoinbase: {},
  loadingCashOutConfig: false,
  loadingCashOut: false,
  loadingVerification: false,
  verificationRequired: false,
  cashOutButtonDisabled: false,
  verifyButtonDisabled: false,
  cashOutErrorMessage: '',
  verifyErrorMessage: '',
  verifyEmail: '',
  lastestCashOutEmailInput: '',
  lnurl: '',
  lnurlQrCode: '',
  refundedPoints: '',
  loadingCancelLightning: false,
  selectedLightningWallet: null,
}

export interface PollLastCashOutStatus {
  baseContext: BaseContext
  pollStatus: 'PENDING_LIGHTNING' | 'PENDING'
}

export const pollLastCashOutStatus = createAsyncThunk(
  'cashoutConfig',
  async ({ baseContext, pollStatus }: PollLastCashOutStatus, { dispatch }) => {
    try {
      if (!firebaseAuth.currentUser) throw new Error('User is not signed in.')

      console.log('pollLastCashOutStatus()')
      const cashoutConfig = httpsCallable(firebaseFunctions, 'cashoutConfig')
      const result = await cashoutConfig(baseContext)

      const data = result.data as CashoutConfig
      console.log(data)
      const lastCashout = data.lastCashout as LastCashout

      if (
        (pollStatus === 'PENDING' && lastCashout.status === 'PROCESSED') ||
        (pollStatus === 'PENDING_LIGHTNING' && (lastCashout.status === 'PENDING' || lastCashout.status === 'EXPIRED_LIGHTNING'))
      ) {
        dispatch(loadConfigs(baseContext))
      }
    } catch (err) {
      dispatch(setServerError(true))
    }
  },
)

export const cashoutConfig = createAsyncThunk('cashoutConfig', async (baseContext: BaseContext, { dispatch }) => {
  try {
    if (!firebaseAuth.currentUser) throw new Error('User is not signed in.')

    dispatch(setRefundedPoints(''))

    const cashoutConfig = httpsCallable(firebaseFunctions, 'cashoutConfig')
    const result = await cashoutConfig(baseContext)

    const data = result.data as CashoutConfig
    console.log(data)
    const lastCashout = data.lastCashout as LastCashout

    const lnurl = lastCashout?.lnurl
    if (lnurl) {
      dispatch(setLnurl(lnurl))
      dispatch(generateQrCode({ lnurl }))
    }

    // We only need the recommended wallets
    const lightningWalletRecs: LightningWallets = {}
    Object.keys(data.lightningWalletWhitelist).forEach((key) => {
      const wallet = data.lightningWalletWhitelist[key]
      if (wallet.recommended) {
        lightningWalletRecs[key] = wallet
      }
    })

    console.log('lightningWalletRecs', lightningWalletRecs)

    const lastCashoutConfig = {
      cashoutId: lastCashout.cashoutId,
      cashoutEmail: lastCashout.cashoutEmail,
      fundsSentAt: lastCashout.fundsSentAt,
      status: lastCashout.status,
      cryptoAmount: lastCashout.cryptoAmount,
      cryptoSymbol: lastCashout.cryptoSymbol,
      cryptoName: lastCashout.cryptoName,
      transferVia: lastCashout.transferVia,
      lnurl: lastCashout.lnurl,
    }

    const config = {
      unavailableCashoutCode: data.unavailableCashoutCode,
      minRequiredPoints: data.minRequiredPoints,
      isCashoutAvailable: data.isCashoutAvailable,
      nextCashoutAvailableAt: data.nextCashoutAvailableAt,
      amountBtc: data.amountBtc,
      currencies: data.currencies,
      platforms: data.platforms,
      lastCashout: lastCashoutConfig,
      lastCashoutEmails: data.lastCashoutEmails,
      pendingReviewMessage: data.pendingReviewMessage,
      lightningWalletWhitelist: lightningWalletRecs,
    }
    dispatch(setCashOutConfig(config))
  } catch (err) {
    dispatch(setServerError(true))
  }
})

export const redeemBlingPoints = createAsyncThunk(
  'redeemBlingPoints',
  async (
    { cashOutContext, lightningUrlScheme }: { cashOutContext: CashOutContext; lightningUrlScheme?: string },
    { dispatch },
  ) => {
    try {
      if (!firebaseAuth.currentUser) throw new Error('User is not signed in.')

      const cashOut = httpsCallable(firebaseFunctions, 'redeemBlingPoints')
      const result = await cashOut(cashOutContext)

      const data = result.data as { action: string; verifyEmail: string; lnurl: string }
      console.log(data)

      const action = data.action
      const lnurl = data.lnurl
      dispatch(setVerifyEmail(data.verifyEmail))

      if (lnurl) {
        dispatch(setLnurl(lnurl))
        dispatch(generateQrCode({ lnurl }))
      }

      if (
        action === CASHOUT_ACTION_TYPE.CASHOUT_EMAIL_CONFIRMATION ||
        action === CASHOUT_ACTION_TYPE.CASHOUT_EMAIL_VERIFICATION ||
        action === CASHOUT_ACTION_TYPE.BLING_EMAIL_CHANGE_CONFIRMATION ||
        action === CASHOUT_ACTION_TYPE.BLING_EMAIL_CHANGE_VERIFICATION ||
        action === CASHOUT_ACTION_TYPE.BLING_EMAIL_VERIFICATION
      ) {
        dispatch(setVerificationRequired(true))
      } else if (action === CASHOUT_ACTION_TYPE.CASHOUT_CREATED) {
        dispatch(setLoadingCashOutConfig(true))
        const baseContext = new BaseContext(cashOutContext.appdeviceguid, cashOutContext.userAgent, cashOutContext.webView)
        Promise.all([dispatch(cashoutConfig(baseContext))]).then(() => {
          dispatch(setLoadingCashOutConfig(false))
          dispatch(setBlingPoints(0))
          dispatch(setBlingPointsBTCValue('0.00000000'))
        })
      } else if (action === CASHOUT_ACTION_TYPE.UNAVAILABLE_EMAIL_ALREADY_USED) {
        dispatch(setCashOutErrorMessage('This Coinbase email has reached the maximum number of Bling Accounts'))
      } else if (action === CASHOUT_ACTION_TYPE.UNAVAILABLE_EMAIL_ACCOUNT_CLOSED) {
        dispatch(setCashOutErrorMessage('This Coinbase email has already been used with a prior account.'))
      } else if (action === CASHOUT_ACTION_TYPE.UNAVAILABLE_EMAIL_TOO_SOON) {
        dispatch(setCashOutErrorMessage('This Coinbase email has been used within the past 7 days.'))
        dispatch(setLatestCashOutEmailInput(cashOutContext.email))
      } else if (action === CASHOUT_ACTION_TYPE.RETRIES_EXHAUSTED) {
        dispatch(setCashOutErrorMessage('Too many failed attempts.'))
      } else if (action === CASHOUT_ACTION_TYPE.UNAVAILABLE_TOO_SOON) {
        dispatch(setCashOutErrorMessage('Cash out already processed.'))
      } else if (action === CASHOUT_ACTION_TYPE.INTERNAL_ERROR || action !== '') {
        dispatch(setCashOutErrorMessage('Something went wrong! Please try again later or contact customer support.'))
      }

      if (
        action !== CASHOUT_ACTION_TYPE.UNAVAILABLE_EMAIL_TOO_SOON ||
        action !== CASHOUT_ACTION_TYPE.UNAVAILABLE_EMAIL_ALREADY_USED
      ) {
        dispatch(setCashOutButtonDisabled(true))
      }

      dispatch(setLoadingCashOut(false))

      if (
        cashOutContext.transferVia.toLowerCase() === 'lightning' &&
        isMobileSafari(cashOutContext.userAgent) &&
        lightningUrlScheme
      ) {
        const lnurlw = `lnurlw://${lnurl}` // replace <lnurl> with actual lnurl
        const url = `${lightningUrlScheme}:${lnurlw}`

        // Open the lightning wallet
        window.open(url, '_blank')
      }
    } catch (err) {
      dispatch(setServerError(true))
    }
  },
)

export const handleVerificationCode = createAsyncThunk(
  'handleVerificationCode',
  async (verificationContext: VerificationContext, { dispatch }) => {
    try {
      if (!firebaseAuth.currentUser) throw new Error('User is not signed in.')

      var verificationCode = httpsCallable(firebaseFunctions, 'handleVerificationCode_V2')
      const result = await verificationCode(verificationContext)

      const data = result.data as { action: string; verifyEmail: string }
      //   console.log(data)
      const action = data.action

      if (data.verifyEmail) {
        dispatch(setVerifyEmail(data.verifyEmail))
        dispatch(setVerificationRequired(true))
      }

      if (action === CASHOUT_ACTION_TYPE.CASHOUT_CREATED) {
        dispatch(setLoadingCashOutConfig(true))
        const baseContext = new BaseContext(
          verificationContext.appdeviceguid,
          verificationContext.userAgent,
          verificationContext.webView,
        )
        Promise.all([dispatch(cashoutConfig(baseContext))]).then(() => {
          dispatch(setLoadingCashOutConfig(false))
          dispatch(setBlingPoints(0))
          dispatch(setBlingPointsBTCValue('0.00000000'))
        })
      } else if (action === CASHOUT_ACTION_TYPE.RETRIES_EXHAUSTED) {
        dispatch(setVerifyErrorMessage('Too many failed attempts.'))
      } else if (action === CASHOUT_ACTION_TYPE.CODE_WRONG_RETRY) {
        dispatch(setVerifyErrorMessage('Invalid code.'))
      } else if (action === CASHOUT_ACTION_TYPE.INTERNAL_ERROR) {
        dispatch(setVerifyErrorMessage('An unexpected error has occurred.'))
      }

      dispatch(setVerifyButtonDisabled(false))
      dispatch(setLoadingVerification(false))
    } catch (err) {
      dispatch(setServerError(true))
    }
  },
)

export const cancelLightningCashOut = createAsyncThunk(
  'cancelLightningCashOut',
  async ({ baseContext, cashOutId }: { baseContext: BaseContext; cashOutId: string }, { dispatch }) => {
    try {
      if (!firebaseAuth.currentUser) throw new Error('User is not signed in.')
      dispatch(setLoadingCancelLightning(true))

      const cashOut = httpsCallable(firebaseFunctions, 'blingsdk-cancelLightningCashOut')
      const result = await cashOut({ ...baseContext, cashOutId })

      const data = result.data as { error: string; cashoutId: string; refundedPoints: string }
      console.log(`cancelLightningCashOutResult: ${JSON.stringify(data)}`)

      dispatch(setRefundedPoints(data.refundedPoints))
    } catch (err) {
      dispatch(setServerError(true))
    } finally {
      dispatch(setLoadingCancelLightning(false))
    }
  },
)

export const generateQrCode = createAsyncThunk('generateQrCode', async ({ lnurl }: { lnurl: string }, { dispatch }) => {
  try {
    const qrCode = await QRCode.toDataURL(lnurl)
    dispatch(setLnurlQrCode(qrCode))
  } catch (err) {
    console.log(`Failed to generate QR code: ${err}`)
  }
})

export const cashOutSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState)
    },
    setCashOutConfig: (state, action: PayloadAction<CashoutConfig>) => {
      state.cashOutConfig = action.payload
    },
    setSelectedCurrency: (state, action: PayloadAction<SupportedCurrency>) => {
      state.selectedCurrency = action.payload
    },
    setSelectedCashoutPlatform: (state, action: PayloadAction<string>) => {
      state.selectedCashoutPlatform = action.payload
    },
    setSelectedCoinbase: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.selectedCoinbase = action.payload
    },
    setLoadingCashOutConfig: (state, action: PayloadAction<boolean>) => {
      state.loadingCashOutConfig = action.payload
    },
    setLoadingCashOut: (state, action: PayloadAction<boolean>) => {
      state.loadingCashOut = action.payload
    },
    setLoadingVerification: (state, action: PayloadAction<boolean>) => {
      state.loadingVerification = action.payload
    },
    setVerificationRequired: (state, action: PayloadAction<boolean>) => {
      state.verificationRequired = action.payload
    },
    setCashOutButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.cashOutButtonDisabled = action.payload
    },
    setVerifyButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.verifyButtonDisabled = action.payload
    },
    setCashOutErrorMessage: (state, action: PayloadAction<string>) => {
      state.cashOutErrorMessage = action.payload
    },
    setVerifyErrorMessage: (state, action: PayloadAction<string>) => {
      state.verifyErrorMessage = action.payload
    },
    setVerifyEmail: (state, action: PayloadAction<string>) => {
      state.verifyEmail = action.payload
    },
    setLatestCashOutEmailInput: (state, action: PayloadAction<string>) => {
      state.lastestCashOutEmailInput = action.payload
    },
    setLnurl: (state, action: PayloadAction<string>) => {
      state.lnurl = action.payload
    },
    setLnurlQrCode: (state, action: PayloadAction<string>) => {
      state.lnurlQrCode = action.payload
    },
    setRefundedPoints: (state, action: PayloadAction<string>) => {
      state.refundedPoints = action.payload
    },
    setLoadingCancelLightning: (state, action: PayloadAction<boolean>) => {
      state.loadingCancelLightning = action.payload
    },
    setSelectedLightningWallet: (state, action: PayloadAction<LightningWallet>) => {
      state.selectedLightningWallet = action.payload
    },
  },
})

export const {
  resetState,
  setCashOutConfig,
  setSelectedCurrency,
  setSelectedCashoutPlatform,
  setSelectedCoinbase, // TODO: Update this. Existing or new coinbase account selection. Too confusing with new platform selection
  setLoadingCashOutConfig,
  setLoadingCashOut,
  setLoadingVerification,
  setVerificationRequired,
  setCashOutButtonDisabled,
  setVerifyButtonDisabled,
  setCashOutErrorMessage,
  setVerifyErrorMessage,
  setVerifyEmail,
  setLatestCashOutEmailInput,
  setLnurl,
  setLnurlQrCode,
  setRefundedPoints,
  setLoadingCancelLightning,
  setSelectedLightningWallet,
} = cashOutSlice.actions
