/**
 * Bling logo in the NavBar
 */

import { Box, IconButton } from '@mui/material'
import Grid from '@mui/material/Grid'
import { BLING_LOGO_BLUE, BLING_LOGO_WHITE } from '../../../constants'
import { loadConfigs } from '../../../redux/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { BaseContext } from '../../../redux/models'
import { AppDispatch, RootState } from '../../../redux/store'

const BlingLogo = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()

  const { appDeviceGuid, userAgent, webView } = useSelector((state: RootState) => state.user)

  const handleClick = () => {
    const baseContext = new BaseContext(appDeviceGuid, userAgent, webView)

    dispatch(loadConfigs(baseContext))
  }

  return (
    <Grid item xs={12}>
      {!props.blueLogo ? (
        <IconButton disableRipple onClick={handleClick} sx={{ cursor: 'pointer', padding: 0 }}>
          <Box component="img" sx={{ width: '80px', marginLeft: '20px', marginTop: '20px' }} src={BLING_LOGO_WHITE} />
        </IconButton>
      ) : (
        <Box
          component="img"
          sx={{ width: props.currency ? '90px' : '200px', marginLeft: '20px', marginTop: '20px' }}
          src={BLING_LOGO_BLUE}
        />
      )}
    </Grid>
  )
}

export default BlingLogo
