import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { firebaseAuth } from '../firebase/firebase'
import { setAuthenticated, setLoadingAuth } from '../redux/slices/auth'
import { setBlingEmail, loadConfigs, setAppDeviceGuid, setWebView, setUserAgent, setSafari } from '../redux/slices/user'
import { AppDispatch } from '../redux/store'
import { onAuthStateChanged } from 'firebase/auth'
import { BaseContext } from '../redux/models'
import { checkWebView, generateGuid, getCookie, setCookie } from '../utils'
import { GUID_COOKIE_NAME } from '../constants'

const AuthStatusRoute = () => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const { webView, userAgent, mobileSafari } = checkWebView()
    dispatch(setWebView(webView))
    dispatch(setUserAgent(userAgent))

    // Specific to lightning cash outs
    if (mobileSafari) {
      console.log('User is on Safari on an iOS device')
      dispatch(setSafari(true))
    } else {
      console.log('User is not on Safari on an iOS device')
      dispatch(setSafari(false))
    }

    // Firebase auth observer to check any auth state changes
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        dispatch(setAuthenticated(!!user))
        // console.log(JSON.stringify(user?.providerData[0]?.email));
        // console.log(user.email);
        // console.log(user.uid);

        // Handle guid
        const cookie = getCookie(GUID_COOKIE_NAME)
        const appDeviceGuid = !cookie ? generateGuid() : cookie
        if (!cookie) setCookie(GUID_COOKIE_NAME, appDeviceGuid)
        dispatch(setAppDeviceGuid(appDeviceGuid))

        const blingEmailFormatted = JSON.stringify(user!.email).replace(/['"]+/g, '')
        dispatch(setBlingEmail(blingEmailFormatted))
        dispatch(loadConfigs(new BaseContext(appDeviceGuid, userAgent, webView)))
        dispatch(setLoadingAuth(false))
      } else {
        dispatch(setLoadingAuth(false))
      }
    })

    return () => {
      unregisterAuthObserver() // Make sure we un-register Firebase observers when the component unmounts.
    }
  }, [dispatch])

  return <Outlet />
}

export default AuthStatusRoute
