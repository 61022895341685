/**
 * Displays the success message after successfully cashing out (before Coinbase processes)
 */
import copy from 'copy-to-clipboard'
import Loader from '../../components/CashOut/Loader/Loader'
import BottomLayout from '../../layouts/CashOut/BottomLayout'
import QrCodeIcon from '@mui/icons-material/QrCode'
import NavBar from '../../components/CashOut/Navbar/NavBar'
import Alert from '@mui/material/Alert'
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { useCallback, useMemo, useState } from 'react'
import { BaseContext } from '../../redux/models'
import { Snackbar } from '@mui/material'
import { usePolling } from '../../hooks'
import {
  CancelLightningDialog,
  CancelLightningSuccessDialog,
  LnurlText,
  LnurlQrCodeDialog,
  WalletDropdown,
  LnurlQrCode,
} from '../../components/CashOut/PendingLightning'

const PendingLightning = () => {
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false)
  const [openQrCodePrompt, setOpenQrCodePrompt] = useState<boolean>(false)
  const [qrCodeConfirmed, setQrCodeConfirmed] = useState<boolean>(false)
  const [qrCodeVisible, setQrCodeVisible] = useState<boolean>(false)
  const [clickedCopy, setClickedCopy] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)

  const { loadingCashOutConfig, lnurl, lnurlQrCode, cashOutConfig, loadingCancelLightning, refundedPoints } = useSelector(
    (state: RootState) => state.cashOut,
  )
  const { appDeviceGuid, userAgent, webView } = useSelector((state: RootState) => state.user)
  const baseContext = useMemo(() => new BaseContext(appDeviceGuid, userAgent, webView), [appDeviceGuid, userAgent, webView])

  usePolling({ baseContext, pollStatus: 'PENDING_LIGHTNING' })

  const handleQrCodeClick = useCallback(() => {
    if (qrCodeConfirmed) setQrCodeVisible((prev) => !prev)
    else setOpenQrCodePrompt(true)
  }, [qrCodeConfirmed])

  const copyLnurl = useCallback(() => {
    copy(lnurl)
    setOpenSnackbar(true) // Open the Snackbar
    clickedCopy ? setClickedCopy(false) : setClickedCopy(true)
  }, [lnurl, clickedCopy])

  return (
    <>
      <NavBar color="black" blueLogo="true" currency="true" />
      {!loadingCancelLightning && !loadingCashOutConfig ? (
        <>
          <Box
            sx={{
              mt: 4,
              mb: 3,
              pb: 2,
              pt: 3,
              pl: { xxs: '15px', sm: '30px' },
              pr: { xxs: '15px', sm: '30px' },
              borderRadius: 1,
              boxShadow: 1,
              bgcolor: 'background.paper',
              maxWidth: '400px',
              width: '90%',
              mx: 'auto',
            }}
          >
            <Grid item xs={12}>
              <Typography align="center" sx={{ marginBottom: '15px', fontWeight: 700, fontSize: { xs: '18px', sm: '20px' } }}>
                Lightning Network Cash Out
              </Typography>

              <WalletDropdown />
            </Grid>

            <Box sx={{ mt: 3, mb: 3, fontWeight: 500 }}>
              <Divider></Divider>
            </Box>

            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
              <Typography component="div" align="center" sx={{ mb: '20px', fontSize: '14px' }}>
                Copy and paste the code into your wallet.
              </Typography>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                <IconButton onClick={handleQrCodeClick} color="primary" sx={{ bgcolor: '#f2f6fa' }}>
                  <QrCodeIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <LnurlText lnurl={lnurl} openCancelDialog={openCancelDialog} copyLnurl={copyLnurl} />
              </Grid>
            </Grid>

            <LnurlQrCodeDialog
              open={openQrCodePrompt}
              onClose={() => setOpenQrCodePrompt(false)}
              onConfirm={() => {
                setOpenQrCodePrompt(false)
                setQrCodeVisible(true)
                setQrCodeConfirmed(true)
              }}
            />
            {qrCodeVisible && <LnurlQrCode lnurlQrCode={lnurlQrCode} />}

            {/* Cancel Lightning Section */}
            <CancelLightningDialog
              baseContext={baseContext}
              cashOutId={cashOutConfig.lastCashout.cashoutId}
              openCancelDialog={openCancelDialog}
              setOpenCancelDialog={setOpenCancelDialog}
            />

            <CancelLightningSuccessDialog baseContext={baseContext} refundedPoints={refundedPoints} />

            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} // Add this line
            >
              <Alert onClose={() => setOpenSnackbar(false)} severity="success">
                Copied!
              </Alert>
            </Snackbar>
          </Box>
        </>
      ) : (
        <BottomLayout>
          <Loader height="35vh" />
        </BottomLayout>
      )}
    </>
  )
}

export default PendingLightning
