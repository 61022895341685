import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { useMemo } from 'react'
import { BaseContext } from '../../../redux/models'
import { usePolling } from '../../../hooks'

const SuccessMessage = () => {
  const { cashOutConfig } = useSelector((state: RootState) => state.cashOut)

  const { appDeviceGuid, userAgent, webView } = useSelector((state: RootState) => state.user)

  const baseContext = useMemo(() => new BaseContext(appDeviceGuid, userAgent, webView), [appDeviceGuid, userAgent, webView])

  usePolling({ baseContext, pollStatus: 'PENDING' })

  return (
    <Grid item xs={12}>
      <Typography
        component="div"
        align="center"
        sx={{ fontSize: { xs: '14px', sm: '16px' }, fontWeight: 500, paddingLeft: '30px', paddingRight: '30px' }}
      >
        <Typography display="inline" sx={{ fontWeight: 500, color: '#00BCD8' }}>
          {cashOutConfig.lastCashout.cryptoSymbol === 'USD' && `\u0024`}
          {cashOutConfig.lastCashout.cryptoAmount} {cashOutConfig.lastCashout.cryptoSymbol}{' '}
        </Typography>
        will be in your {cashOutConfig.lastCashout.transferVia}{' '}
        {cashOutConfig.lastCashout.transferVia.toLowerCase() === 'LIGHTNING' ? 'account' : 'wallet'} within minutes.
      </Typography>

      <Typography align="center" sx={{ fontSize: '11px', paddingTop: '15px' }}>
        Please allow up to 48 hours before contacting support.
      </Typography>
    </Grid>
  )
}

export default SuccessMessage
