/**
 * Route the user to the correct cash out page
 */
import { useSelector } from 'react-redux'
import { useLocation, Outlet, Navigate } from 'react-router-dom'
import { CASHOUT_UNAVAILABLE_CODE, CASHOUT_STATUS } from '../constants'
import { RootState } from '../redux/store'
import InternalServer from '../components/Errors/InternalServer'
import LoadingRoute from './LoadingRoute'

const CashOutRoute = () => {
  let navigateToPath = ''
  const location = useLocation()
  const cashOutPath = location.pathname.split('/')[2] // ex) ['account.blingfi.com', 'cash-out', 'verification]
  const { serverError, loadingAuth } = useSelector((state: RootState) => state.auth)
  const { loadingConfigs } = useSelector((state: RootState) => state.user)
  const { selectedCurrency, selectedCashoutPlatform, verificationRequired, selectedCoinbase, cashOutConfig } = useSelector(
    (state: RootState) => state.cashOut,
  )

  // Determines which cash out page to display based on users data
  if (loadingAuth || (loadingConfigs.userRewardConfig && loadingConfigs.cashOutConfig)) {
    return <LoadingRoute />
  } else if (cashOutConfig.unavailableCashoutCode === CASHOUT_UNAVAILABLE_CODE.ACCOUNT_PENDING_REVIEW) {
    navigateToPath = 'account-pending-review'
  } else if (cashOutConfig.unavailableCashoutCode === CASHOUT_UNAVAILABLE_CODE.BELOW_MINIMUM_POINTS && selectedCurrency.name) {
    navigateToPath = 'minimum-points'
  } else if (
    cashOutConfig.unavailableCashoutCode === CASHOUT_UNAVAILABLE_CODE.TOO_SOON &&
    cashOutConfig.lastCashout.status === CASHOUT_STATUS.PROCESSED
  ) {
    navigateToPath = 'latest'
  } else if (
    cashOutConfig.unavailableCashoutCode === CASHOUT_UNAVAILABLE_CODE.TOO_SOON &&
    cashOutConfig.lastCashout.status === CASHOUT_STATUS.PENDING_LIGHTNING
  ) {
    navigateToPath = 'pending-lightning'
  } else if (cashOutConfig.unavailableCashoutCode === CASHOUT_UNAVAILABLE_CODE.TOO_SOON) {
    navigateToPath = 'success'
  } else if (selectedCurrency.name && verificationRequired) {
    navigateToPath = 'verification'
  } else if (
    selectedCurrency.name &&
    !selectedCoinbase.selected &&
    !cashOutConfig.lastCashout.cashoutEmail &&
    selectedCashoutPlatform.toLowerCase() !== 'lightning'
  ) {
    // TODO: This should show per platform using the selectedCashoutPlatform as a key for the keycashoutConfig.lastCashoutEmails object. Also rename this flow
    navigateToPath = 'coinbase'
  } else if (selectedCurrency.name && cashOutConfig.isCashoutAvailable) {
    navigateToPath = 'process'
  } else if (
    cashOutConfig.unavailableCashoutCode !== CASHOUT_UNAVAILABLE_CODE.TOO_SOON &&
    !selectedCurrency.name &&
    selectedCashoutPlatform
  ) {
    navigateToPath = 'currency'
  } else if (cashOutConfig.unavailableCashoutCode !== CASHOUT_UNAVAILABLE_CODE.TOO_SOON && !selectedCashoutPlatform) {
    navigateToPath = 'platform'
  } else if (serverError) {
    return <InternalServer />
  } else {
    return <InternalServer />
  }

  // Avoid needing to route back to CashOutRoute (this route), and instead route to the nested cash out route
  if (navigateToPath === cashOutPath) {
    return <Outlet />
  }

  return <Navigate to={navigateToPath} />
}

export default CashOutRoute
