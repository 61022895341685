import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const LatestCashOutDetails = (props: any) => {
  const { cashOutConfig } = useSelector((state: RootState) => state.cashOut)

  return (
    <Grid item xs={12}>
      <Typography align="center" sx={{ fontWeight: 700, fontSize: '15px' }}>
        was sent to your {cashOutConfig.lastCashout.transferVia}{' '}
        {cashOutConfig.lastCashout.transferVia.toLowerCase() === 'lightning' ? 'wallet' : 'account'}
      </Typography>

      <Typography component="div" align="center" sx={{ fontWeight: 700, fontSize: '15px' }}>
        {props.cashOutEmail && (
          <Typography display="inline" sx={{ color: '#00BCD8', fontWeight: 700, fontSize: '15px' }}>
            ({props.cashOutEmail} )
          </Typography>
        )}
        on {}
        {props.formattedDate}
      </Typography>
    </Grid>
  )
}

export default LatestCashOutDetails
