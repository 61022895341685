import { initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

/**
 * Handle Firebase environment (including App Check) using local environment variables
 * to set up the Firebase configuration. Defaults to eric-staging
 */
let appCheckEnv = process.env.REACT_APP_ERIC_STAGING_FIREBASE_APP_CHECK_KEY
let firebaseConfig = {
  apiKey: process.env.REACT_APP_ERIC_STAGING_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ERIC_STAGING_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_ERIC_STAGING_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_ERIC_STAGING_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ERIC_STAGING_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ERIC_STAGING_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ERIC_STAGING_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ERIC_STAGING_FIREBASE_MEASUREMENT_ID,
}
if (process.env.REACT_APP_FIREBASE_ENV === 'anthonyStaging') {
  appCheckEnv = process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_APP_CHECK_KEY
  firebaseConfig = {
    apiKey: process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_ANTHONY_STAGING_FIREBASE_MEASUREMENT_ID,
  }
}

if (process.env.REACT_APP_FIREBASE_ENV === 'staging') {
  appCheckEnv = process.env.REACT_APP_STAGING_FIREBASE_APP_CHECK_KEY
  firebaseConfig = {
    apiKey: process.env.REACT_APP_STAGING_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_STAGING_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_STAGING_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_STAGING_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STAGING_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_STAGING_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_STAGING_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_STAGING_FIREBASE_MEASUREMENT_ID,
  }
}
if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
  appCheckEnv = process.env.REACT_APP_PRODUCTION_FIREBASE_APP_CHECK_KEY
  firebaseConfig = {
    apiKey: process.env.REACT_APP_PRODUCTION_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PRODUCTION_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PRODUCTION_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_PRODUCTION_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PRODUCTION_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PRODUCTION_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PRODUCTION_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_PRODUCTION_FIREBASE_MEASUREMENT_ID,
  }
}

/**
 * Access calls to cloud functions, authentication, and FirebaseUI
 */
export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseFunctions = getFunctions(firebaseApp)
export const firebaseAnalytics = getAnalytics(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseFirestore = getFirestore(firebaseApp)

// Add debug token for local development
if (process.env.REACT_APP_APP_CHECK_DEBUG && process.env.REACT_APP_APP_CHECK_DEBUG === 'true') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

// Firebase Emulator for local testing
if (process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true

  connectAuthEmulator(firebaseAuth, 'http://localhost:9099')
  connectFirestoreEmulator(firebaseFirestore, 'localhost', 8080)
  connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001)
}

/**
 * Connect App Check with the public key
 */
initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(appCheckEnv!),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
})
